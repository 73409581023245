import React from 'react';
import PropTypes from 'prop-types';
import WeatherCard from './WeatherCard'
import { MapContext } from '../MapCoordinator';

class WeatherForecast extends React.Component {
    constructor(props) {
        super (props);
    }

    render () {
      var city = this.props.weatherInfo.get(this.context.state.selectedCity);
      if (!city) {
        city =  this.props.weatherInfo.get("Palma");
        console.log(this.context.state.selectedCity + ", Not found");
      } 
      // const sunrise = new Date(0);
      // sunrise.setUTCSeconds(city.sunrise);
      // const sunset = new Date(0);
      // sunset.setUTCSeconds(city.sunset);
      const selectedDate = new Date(0);
      selectedDate.setUTCSeconds(this.context.state.selectedTime);

      return (
        <div>
            <div className="columns is-gapless">
              <div className="column has-padding-5">
                <div className="card is-shadowless" onClick={this.daySelected}>
                  <div className="card-content has-padding-top-15">
                    <div className="has-text-centered has-text-weight-bold is-size-5 ">
                      <h1>{this.context.state.selectedCity}</h1>
                    </div>
                    <div>
                      <p className="has-text-centered is-size-7">at 12:00 pm</p>
                    </div>
                    {/* <div class="has-text-centered has-text-weight-bold ">
                      <p>{sunrise.toLocaleTimeString().substring(0,5)}</p>
                      <p>{sunset.toLocaleTimeString().substring(0,5)}</p>
                    </div> */}
                  </div>
                </div>
            </div>
            {this.props.days && this.props.days.map((day) => {
              var info = city.forecasts.get(day);
              return (
                <div className="column has-padding-5" key={day}>
                  <WeatherCard forecast={info} onDaySelected={()=>{this.context.setSelectedTime(info.dt_txt)}}/>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
}
WeatherForecast.contextType = MapContext;

WeatherForecast.propTypes = {
  weatherInfo: PropTypes.object,
  days: PropTypes.array,
};
export default WeatherForecast;