
import React from 'react';
import { Link, graphql } from 'gatsby'
import PropTypes from 'prop-types';
import { MapContext } from './MapCoordinator'


class SelectedPost extends React.Component {

    constructor(props) {
        super(props);
    }
    render() {
        if (this.props.blogPostsMap.size ==0 || !this.props.blogPostsMap.get(this.context.state.selectedBlogTitle))
            return <div/>;

        return (
            <div className="container">
                <div className="columns">
                    <div className="columnn">
                        <center>
                            <iframe
                                src={this.props.blogPostsMap.get(this.context.state.selectedBlogTitle).node.frontmatter.mediaurl}
                                frameBorder="0"
                                scrolling="no"
                                width="320"
                                height="320"
                            ></iframe>
                        </center>
                    </div>
                    <div className="column">
                        <Link className="has-text-grey-dark" to={this.props.blogPostsMap.get(this.context.state.selectedBlogTitle).node.fields.slug}>
                        <div >
                            <h1 className="is-size-4 is-spaced has-text-centered has-text-weight-bold is-bold-light">
                                {this.props.blogPostsMap.get(this.context.state.selectedBlogTitle).node.frontmatter.title}
                            </h1>
                        </div>
                        <br/>
                        <div>
                             {this.props.blogPostsMap.get(this.context.state.selectedBlogTitle).node.frontmatter.longdescription}
                        </div>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

SelectedPost.contextType = MapContext;
SelectedPost.propTypes = {
    blogPostsMap: PropTypes.object,
}

export default SelectedPost;