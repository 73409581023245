import wiDaySunny from './../icons/wi-day-sunny.svg';
import wiNightClear from './../icons/wi-night-clear.svg';
import wiDayCloudy from './../icons/wi-day-cloudy.svg';
import wiNightCloudy from './../icons/wi-night-cloudy.svg';
// import wiDayCloudyHigh from './../icons/wi-day-cloudy-high.svg';//no
// import wiNightCloudyHigh from './../icons/wi-night-cloudy-high.svg';//no
import wiDaySunnyOvercast from './../icons/wi-day-sunny-overcast.svg';
// import wiNightPartlyCloudy from './../icons/wi-night-alt-partly-cloudy.svg';//no
import wiDayShowers from './../icons/wi-day-showers.svg';
import wiNightShowers from './../icons/wi-night-showers.svg';
import wiDayRain from './../icons/wi-day-rain.svg';
import wiNightRain from './../icons/wi-night-rain.svg';
// import wiDayThunderstorm from './../icons/wi-day-thunderstorm.svg';//no
// import wiNightThunderstorm from './../icons/wi-night-thunderstorm.svg';//no
// import wiDaySnow from './../icons/wi-day-snow.svg';//no
// import wiNightSnow from './../icons/wi-night-snow.svg';//no
import wiDayRainMix from './../icons/wi-day-rain-mix.svg';
import wiNightRainMix from './../icons/wi-night-rain-mix.svg';
// import wiMeteor from './../icons/wi-meteor.svg';//no
import wiCloud from './../icons/wi-cloud.svg';
import wiCloudWindy from './../icons/wi-day-cloudy-windy.svg';
import wiDayCloudyWindy from './../icons/wi-day-cloudy-windy.svg';
import wiDayFog from './../icons/wi-day-fog.svg';
import wiDayHaze from './../icons/wi-day-haze.svg';
import wiDayLightning from './../icons/wi-day-lightning.svg';
import wiDayStormShowers from './../icons/wi-day-storm-showers.svg';
import wiNightFog from './../icons/wi-night-fog.svg';
import wiNightLightning from './../icons/wi-night-lightning.svg';
import wiRainMix from './../icons/wi-rain-mix.svg';
import wiRain from './../icons/wi-rain.svg';
import wiShowers from './../icons/wi-showers.svg';
//Blog
import coffee from './../icons/Coffee.svg';
import drink from './../icons/Drink.svg';
import beach from './../icons/Beach.svg';
import eat from './../icons/Eat.svg';
import hiking from './../icons/Hiking.svg';
import snorkel from './../icons/Snorkel.svg';
import wine from './../icons/Wine.svg';
import town from './../icons/Town.svg';

/**
 * Selects the name for an image
 * @param {string} name the name given to you by OpenWeatherMap
 * @returns {string} returns the string for the image
 */
const imageSelector = (name) => {

    let image = wiDaySunnyOvercast;
    switch (name) {
        case 'beach':
            image = beach;
            break;
        case 'coffee':
            image = coffee;
            break;
        case 'drink':
            image = drink;
            break;
        case 'eat':
            image = eat;
            break;
        case 'hiking':
            image = hiking;
            break;
        case 'snorkel':
            image = snorkel;
            break;
        case 'wine':
            image = wine;
            break;
        case 'town':
            image = town;
            break;
            
        case '01d':
            image = wiDaySunny;
            break;
        case '01n':
            image = wiNightClear;
            break;
        case '02d':
            image = wiDayCloudy;
            break;
        case '02n':
            image = wiNightCloudy;
            break;
        // case '03d':
        //   image = wiDayCloudyHigh;
        //   break;
        // case '03n':
        //   image = wiNightCloudyHigh;
        //   break;
        // case '04d':
        //     image = wiDaySunnyOvercast;
        //     break;
        // case '04n':
        //   image = wiNightPartlyCloudy;
        //   break;
        case '09d':
            image = wiDayShowers;
            break;
        case '09n':
            image = wiNightShowers;
            break;

        // case '50n':
        //     image = wiShowers
        //     break;
        // case '50n':
        //     image = wiDayStormShowers            
        //     break;
        // case '13d':
        //   image = wiDaySnow;
        //   break;
        // case '13n':
        //   image = wiNightSnow;
        //   break;
        case '10d':
            image = wiDayRainMix;
            break;
        case '10n':
            image = wiNightRainMix;
            break;

        // case '10d':
        //     image = wiDayRain;
        //     break;
        // case '10n':
        //     image = wiNightRain;
        //     break;

        case '04n':
        case '04d':
        case '03n':
        case '03d':
            image = wiCloud
            break;

        // case '50n':
        //     image = wiCloudWindy            
        //     break;
        // case '50n':
        //     image = wiDayCloudyWindy            
        //     break;

        // case '50n':
        // case '50d':
        //     image = wiDayHaze
        //     break;

        case '11d':
            image = wiDayLightning
            break;
        case '11n':
            image = wiNightLightning
            break;

        case '50n':
            image = wiNightFog
            break;
        case '50d':
            image = wiDayFog
            break;

        // case '50n':
        //     image = wiRainMix            
        //     break;
        // case '50n':
        //     image = wiRain            
        //     break;

        default:
            // http://openweathermap.org/img/w/10d.png
            image = wiDaySunnyOvercast;
            break;
    }
    return image;
};

export default imageSelector;