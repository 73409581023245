import React from 'react'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from "gatsby"

const ImgReSize = (props) => (
    <StaticQuery
        query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                sizes(maxWidth: 640, maxHeight: 480) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
        if (!props.filename) { 
            return (
                <img src="https://via.placeholder.com/320x240.png?text=MallorcaSecrets" alt="koala"></img>
            );
        } else {
            const image = data.images.edges.find(n => {
                return n.node.relativePath.includes(props.filename);
            });
            if (!image) { 
                return (
                    <img src="https://via.placeholder.com/320x240.png?text=MallorcaSecrets" alt="koala"></img>  
                );
            } 

            const imageSizes = image.node.childImageSharp.sizes;
            return (
                <Img 
                    alt={props.alt}
                    sizes={imageSizes}
                    style={props.style} 
                />
            );
        }
    }}
    />
)

export default ImgReSize