import React from 'react'
import PropTypes from 'prop-types'
import {GoogleMapProvider, MapBox, Marker} from '@googlemap-react/core'
import GPlaceMarker from './GPlaceMarker'
import { MapContext } from '../MapCoordinator';

 class GMaps extends React.Component {

    constructor(props) {
        super(props);
    }


    render() {
        // Weather Markers
        const mapWeatherMarkers  = Array.from( this.props.weatherInfo ).map(([key, city]) => {
            return (
                <GPlaceMarker
                    key = {city.coord.lat + city.coord.lng}
                    placeName = {city.name} 
                    placeType = "Weather"
                    placeWeatherForecasts = {city.forecasts}
                    placeCoords = {city.coord}
                    onMarkerSelected={()=>{this.context.setSelectedCity(city.name)}}/>
            );
        });
        // Blog makers
        const mapPlacesMarkers = Array.from(this.props.placesMarkers).map((place) => {
            var x =  Array.from(place.node.frontmatter.coords).map((coord) => {
                var y = 
                    <GPlaceMarker
                        key = {coord.lat + coord.lng}
                        placeName = {place.node.frontmatter.place} 
                        placeType = "Post"
                        placeIcon = {coord.icon}
                        blogTitle = {place.node.frontmatter.title}
                        placeCoords = {{
                            lat: parseFloat(coord.lat),
                            lng: parseFloat(coord.lng),
                        }}
                        onMarkerSelected={()=>{
                            this.context.setSelectedBlogTitle(place.node.frontmatter.title)
                            this.context.setSelectedCity(place.node.frontmatter.place)
                        }}/>
                return y;    
            });
            return x;
        }); 
        const mapMarkers = [...mapWeatherMarkers, ...mapPlacesMarkers];
        return(
            <GoogleMapProvider>
                <MapBox style={{ width: '100%', height: '60vh', position: 'relative' }} 
                    apiKey="AIzaSyCsupbGgzhEU11qdtvg23dqnBhMtJ8Qe4o"
                    opts={{
                        center: { 
                            lat: 39.65,
                            lng: 2.95
                        },
                        zoom: 9,
                        disableDefaultUI: true,
                        zoomControl: true

                    }}
                    onCenterChanged={() => {
                        console.log('The center of the map has changed.')
                    }}
                />
                {mapMarkers}
            </GoogleMapProvider >
        )
    }
}
GMaps.contextType = MapContext;
GMaps.propTypes = {
    weatherMarkers: PropTypes.array,
    placesMarkers: PropTypes.array,
    weatherInfo: PropTypes.object,
}

export default GMaps;
