import React from 'react';
import PropTypes from 'prop-types';
import imageSelector from './IconSelector';
import { withTranslation } from 'react-i18next';

class WeatherCard extends React.Component {
  
  constructor(props) {
    super(props);
  }

  daySelected = (e) => {
    this.props.onDaySelected(this.props.forecast.dt_txt);
  }

  render() {
    const day = new Date(0);
    day.setUTCSeconds(this.props.forecast.dt);

    return (
          <div className="card is-shadowless" onClick={this.daySelected}>
            <div className="card-content has-padding-5">
              <p className=" has-text-centered is-size-6">
                {/* <p className={`has-text-centered is-size-6  ${this.state.navBarActiveClass}`}> */}
                {this.props.t(day.getDay()===(new Date()).getDay()? "7" : day.getDay())}
              </p>
              <div className="card-image has-text-centered" >
                {/* <figure className="image has-text-centered  is-inline-block" style={{textAlign: "center"}}>  */}
                  <img src={imageSelector(this.props.forecast.icon)} alt='sun' style={{ padding:0, margin:0, width: "55%", height: "55%"}}  />
                {/* </figure> */}
              </div>
              <div className="has-text-centered is-size-7 ">
                <p>{this.props.forecast.feels_like}&#176;</p>
                {/* <p>{this.props.forecast.ftemp_max}&#176;</p>
                <p>{this.props.forecast.ftemp_min}&#176;</p> */}
              </div>
              {/* <div class="has-text-centered has-text-weight-bold ">
                <p>  {this.props.forecast.wSpeed} | {this.props.forecast.wDeg}&#176;</p>
              </div> */}
              {/* <div class="card-image has-text-centered">
                <p >{this.props.forecast.description}</p>
              </div> */}
            </div>
          </div>
    );
  }
}

WeatherCard.propTypes = {
  forecast: PropTypes.object,
  onDaySelected: PropTypes.func,
};
export default withTranslation()(WeatherCard);
