import React from 'react' 
import PropTypes from 'prop-types';
// import WeatherIcon from '../weather/WeatherIcon'

import { Marker, GoogleMapState} from '@googlemap-react/core'
import imageSelector from '../weather/IconSelector';
import { MapContext } from '../MapCoordinator'

class GPlaceMarker  extends React.Component { 
    constructor(props) {
        super(props);
    }

    markerSelected =(e) => {
        this.props.onMarkerSelected();
        // google.maps.Marker.MAX_ZINDEX + 1;
        // google.maps.Animation.DROP
    }

    render() {
        if (this.props.placeType === "Post" ){
            
            return (
                <Marker opts={{
                    position: this.props.placeCoords,
                    // animation: this.props.blogTitle === this.context.state.selectedBlogTitle ? 1:0,
                    icon:  {
                        url: imageSelector(this.props.placeIcon),
                        scaledSize: {
                            height: this.props.blogTitle === this.context.state.selectedBlogTitle ? 60 : 30,
                            width:  this.props.blogTitle === this.context.state.selectedBlogTitle ? 60 : 30,
                        }
                    },
                    optimized: false,
                    zIndex: this.props.blogTitle === this.context.state.selectedBlogTitle ? 20000:10000,
                }}
                onClick ={(e) =>this.markerSelected(e) } />
            )
        } else if (this.props.placeType === "Weather"){
            return (
                <Marker opts={{
                    position: this.props.placeCoords,
                    icon:  {
                        url: imageSelector(this.props.placeWeatherForecasts.get(this.context.state.selectedTime).icon),
                        scaledSize: {
                            height: this.props.placeName === this.context.state.selectedCity ? 70 : 30,
                            width: this.props.placeName === this.context.state.selectedCity ? 70 : 30,
                        }
                    }
                }}
                onClick={this.markerSelected} />
            )
        }
    }
}

GPlaceMarker.contextType = MapContext;
GPlaceMarker.propTypes = {
    placeName : PropTypes.string,
    blogTitle : PropTypes.string,
    placeType : PropTypes.string,
    placeIcon : PropTypes.string,
    placeWeatherForecasts : PropTypes.object,
    placeCoords : PropTypes.object,
    onMarkerSelected: PropTypes.func,
}

export default GPlaceMarker;