import React from 'react';
import PropTypes from 'prop-types';
export const MapContext = React.createContext();  //exporting context object

class MapCoordinator extends React.Component {
    constructor(props) {
        super(props);
    }
    state = { 
        selectedTime: this.props.timeState, 
        selectedCity: this.props.cityState, 
        selectedBlogTitle: this.props.blogState
    }
   
    render() {
        return (
            <MapContext.Provider value={
                {
                    state: this.state,
                    setSelectedTime: (value) => this.setState({
                        selectedTime: value
                    }),
                    setSelectedCity: (value) => this.setState({
                        selectedCity: value
                    }),
                    setSelectedBlogTitle: (value) => this.setState({
                        selectedBlogTitle: value
                    }),
                }}>
                {this.props.children}
            </MapContext.Provider>)
    }
}
MapCoordinator.propTypes = {
    timeState: PropTypes.string,
    cityState: PropTypes.string,
    blogState: PropTypes.string,
};
export default MapCoordinator;