import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import ImgReSize from '../components/imgs/ImgReSize'

import './i18n';
import { withTranslation } from 'react-i18next';
import { withI18next } from 'gatsby-plugin-i18next';

import Layout from '../components/Layout'
import GMaps from '../components/maps/GMaps'
import WeatherForecast from '../components/weather/WeatherForecast'
import MapCoordinator, { MapContext } from '../components/MapCoordinator'
import SelectedPost from '../components/SelectedPost'


class IndexPage extends React.Component {
  constructor(props) {
    super(props);

  }

  render() {
    const { data } = this.props;
    const { edges: posts } = data.posts;
    const { nodes: forecasts } = data.forecasts;

    //Map and Weather calculations
    const days = new Array();
    const cities = new Map();
    var diffDay = "";
    forecasts.forEach((forecast) => {
      const forecastsMap = new Map();
      forecast.city.forecasts.forEach((forecast) => {
        forecastsMap.set(forecast.dt_txt, forecast);
        var nextDay = forecast.dt_txt.substring(0, 10);
        if (diffDay !== "stop" && diffDay !== nextDay && forecast.dt_txt.includes("12:00")) {
          days.push(forecast.dt_txt);
          diffDay = nextDay;
        }
      });
      diffDay = "stop";
      forecast.city.forecasts = forecastsMap;
      cities.set(forecast.city.name, forecast.city);
    })

    const blogPostsMarkers = Array.from(posts).filter(post => post.node.frontmatter.coords);
    const blogPostsMap = new Map();
    blogPostsMarkers.forEach((post)=> blogPostsMap.set(post.node.frontmatter.title, post));

    return (
      <Layout>
        <MapCoordinator timeState={days[0]} cityState={"Palma"} blogState={this.props.t('defaultBlog')}>
          <div>
            <GMaps placesMarkers={blogPostsMarkers} weatherInfo={cities} />
          </div>
          <div className="container is-hidden-mobile">
            <div className="columns is-desktop">
              <div className="column is-half is-offset-one-quarter">
                <div className="container">
                  <WeatherForecast weatherInfo={cities} days={days} />
                </div>
              </div>
            </div>
          </div>
          <section>
            <div className="columns is-mobile">
              <div className="column is-half is-offset-one-quarter">
                <SelectedPost blogPostsMap={blogPostsMap} />
              </div>
            </div>
          </section>
        </MapCoordinator>
        <section className="section">
          <div className="container">
            <div className="content">
              <div className="columns is-multiline" >
                {posts && posts.map(({ node: post }) => (
                  <div className="column is-one-third-desktop is-half-tablet" key={post.id} >
                    <Link className="has-text-grey-dark" to={post.fields.slug}>
                      <div className="card is-shadowless">
                        <div className="card-image">
                          <ImgReSize filename={post.frontmatter.image === null ? null : post.frontmatter.image.relativePath} alt={post.frontmatter.title}></ImgReSize>
                        </div>
                        <div className="card-content-x">
                          <div className="content">
                            <div className="has-text-centered">
                              <h2>{post.frontmatter.title}</h2>
                            </div>
                            <div className="has-text-centered">
                              {post.frontmatter.description}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </Layout >
    )
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
    allForecasts: PropTypes.shape({
      nodes: PropTypes.array,
    })
  }),
}

IndexPage.contextType = MapContext;

export default withI18next()(withTranslation()(IndexPage));

export const blogRollQuery = graphql`
  query BlogRollQuery ($locale: String!, $menuCategory: String!) {
    posts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" }, language: { eq: $locale }, menuCategory: { regex: $menuCategory}}}
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            language
            title
            titleseo
            description
            descriptionseo
            longdescription
            templateKey
            date(formatString: "MMMM DD, YYYY")
            image {
              absolutePath
              relativePath
            }
            mediaurl
            place
            coords {
              icon
              lat
              lng
            }
          }
        }
      }
    }
    forecasts: allForecasts {
      nodes {
        city {
          name
          sunset
          sunrise
          coord {
            lat
            lng
          }
          forecasts {
            id
            description
            dt
            dt_txt
            feels_like
            ftemp_max
            ftemp_min
            icon
            wDeg
            wSpeed
          }
        }
      }
    }
  }
`;
