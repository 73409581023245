import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      "title": "Mallorca Secrets Blog - Discover the Island of Mallorca",
      "description": "Explore the island of Majorca with our local insider tips: the prettiest beaches and villages, the best cafes and restaurants and recommendations for a city trip to Palma de Mallorca",
      "home" : "X",
      "home_link" : "/en",
      "beach" : "Beaches",
      "beach_link" : "/en/beaches",
      "places" : "Places",
      "places_link" : "/en/places",
      "activities" : "Activities",
      "activities_link" : "/en/activities",
      "food" : "Food & Drink ",
      "food_link" : "/en/food",
      "0": "SUN",
      "1": "MON",
      "2": "TUE",
      "3": "WED",
      "4": "THU",
      "5": "FRI",
      "6": "SAT",
      "7": "Today",
      "defaultBlog" : "Breakfast in Palma",
      "terms" : "Hey there! It is important to us to find out what topics our readers are interested in and how we can improve our articles. This is why, we are collecting cookies. Please click on \"Accept\" and help us to get better!",
      "accept" : "Accept"
    }
  },
  de: {
    translation: {
      "title": "Mallorca Secrets Reiseblog - die besten Tipps für die Baleareninsel",
      "description": "Entdecke top Insider Tipps für deinen Urlaub auf Mallorca. Die schönsten Strände und Dörfer, die besten Cafés & Restaurants und Tipps für einen Ausflug nach Palma",
      "home" : "X",
      "home_link" : "/de",
      "beach" : "Strände",
      "beach_link" : "/de/strände",
      "places" : "Orte",
      "places_link" : "/de/orte",
      "activities" : "Aktivitäten",
      "activities_link" : "/de/aktivitäten",
      "food" : "Essen & Trinken",
      "food_link" : "/de/essen",
      "0": "SUN",
      "1": "MON",
      "2": "TUE",
      "3": "WED",
      "4": "THU",
      "5": "FRI",
      "6": "SAT",
      "7": "Today",
      "defaultBlog" : "Palma Sehenswürdigkeiten und Insider Tipps",
      "terms" : "Hey! Uns ist es wichtig herauszufinden, welche Themen unsere Leser interessieren und wie wir unsere Artikel verbessern können. Daher sammeln wir Cookies. Bitte klick auf \"Akzeptieren\" und hilf uns damit besser zu werden.",
      "accept" : "Akzeptieren"
    }
  }
};
i18n
.use(LanguageDetector)
.use(initReactI18next)
.init({
  detection: {
    order: ['path', 'navigator', 'htmlTag' ],
  lookupFromPathIndex: 0,
  checkWhitelist: true,
  },
  resources,
  fallbackLng: 'en', 
  keySeparator: false, // we do not use keys in form messages.welcome
  interpolation: {
    escapeValue: false // react already safes from xss
  }
});
export default i18n;